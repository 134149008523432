`use strict`;

import Xhr from '../libs/xhr';

class Cart {
    constructor() {
        window.selectors = window.selectors || {};

        window.selectors.addToCartBtn = `.js-add-to-cart-btn`;
        window.selectors.changeQuantityBtn = `.js-change-quantity-from-cart`;
        window.selectors.useCashback = `.js-use-cashback`;
        window.selectors.wrapperWithCashback = `.js-summary-with-cashback`;
        window.selectors.orderSummaryTotalPriceText = `.js-order-summary-total-price`;
        window.selectors.viewCartButton = `.js-view-cart`;
        window.selectors.closeCartButton = `.js-close-cart`;

        this.initAddToCartListener();
        this.initChangeProductQuantityListener();
        this.initUseCashbackListener();
        this.initViewCartButton();
        this.initCloseCartButton();
    }

    initAddToCartListener() 
    {
        $('body').on('click', window.selectors.addToCartBtn, function(e) 
        {
            e.preventDefault();
            let productID = $(this).data('product');
            let quantity = 1;
            let operation = 'add'; 

            // quantity from input text
            if($(`.js-add-to-cart-quantity`).length) {
                let qty = parseInt($('.js-add-to-cart-quantity').val());
                if(qty > 0 && qty < 5000) {
                    quantity = qty
                } else {
                    alert('Cantitatea nu poate fi procesata.');
                    return false;
                }
            }

            // ajax
            // 1. update number of products from cosul meu
            // 2. show modal with a small order summary and buttons like: Vezi Cos, Continua cumparaturile

            return new Promise((resolve, reject) => {
                let options = {
                    url: window.Laravel.addToCartUrl,

                    data: {
                        productID: productID,
                        quantity: quantity,
                        operation: operation
                    },

                    success: function(data) {
                        if (data.status === 'OK') {
                            // populate modal content with new data
                            $('.js-cart-content').html(data.message);

                            // open modal dialog
                            let dialog = $('#cart-info-modal').dialog({
                                modal: true,
                                autoOpen: false,
                                resizable: false,
                                draggable: false
                            });

                            window.scrollTo(0, 0);

                            dialog.dialog('open');

                            // update Cosul meu quantity
                            $('.js-quantity-text').css('display', '').html(data.total_cart_quantity);

                            let AddToCartOptions = {
                                content_name: data.content.product.name, 
                                content_ids: [data.content.product.code],
                                content_type: 'product',
                                value: String(data.content.price),
                                currency: 'RON'
                            }
                            // facebook pixel event
                            fbq('track', 'AddToCart', AddToCartOptions);
                        } else if (data.status === 'ERROR') {
                            reject(data.message);
                            return;
                        }
                    },

                    error: function(xhr) {
                        reject(xhr.responseJson);
                    }
                };

                let xhr = new Xhr();
                xhr.setOptions(options);
                xhr.execute();
            });
        });
    }

    initChangeProductQuantityListener() 
    {
        $('body').on('click', window.selectors.changeQuantityBtn, function(e) {
            let productID = $(this).data('product-id');
            let quantity = $(this).data('quantity');
            let operation = $(this).data('operation');

            return new Promise((resolve, reject) => {
                let options = {
                    url: window.Laravel.addToCartUrl,

                    data: {
                        productID: productID,
                        quantity: quantity,
                        operation: operation
                    },

                    success: function(data) {
                        if (data.status === 'OK') {
                            window.location.href = window.Laravel.seeCart;
                        } else if (data.status === 'ERROR') {
                            reject(data.message);
                            return;
                        }
                    },

                    error: function(xhr) {
                        reject('An error has occurred on updating the cart');
                    }
                };

                let xhr = new Xhr();
                xhr.setOptions(options);
                xhr.execute();
            });
        });
    }

    initUseCashbackListener() 
    {
        $('body').on('click', window.selectors.useCashback, function() 
        {
            let useCashback = $(this).is(':checked') ? 1 : 0;

            return new Promise((resolve, reject) => {
                let options = {
                    url: window.Laravel.handleSessionsURL,

                    data: {
                        mode: 'put',
                        useCashback: useCashback
                    },

                    success: function(data) {
                        if (data.status === 'OK') {
                            window.location.href = window.Laravel.seeCart;
                        }
                    },

                    error: function(xhr) {
                        reject('An error has occurred');
                    }
                };

                let xhr = new Xhr();
                xhr.setOptions(options);
                xhr.execute();
            });
        });
    }

    initViewCartButton()
    {
        $('body').on('click', window.selectors.viewCartButton, function(e) {
            window.location.href = window.Laravel.seeCart;
        })
    }

    initCloseCartButton()
    {
        $('body').on('click', window.selectors.closeCartButton, function(e) {
            $('#cart-info-modal').dialog('close');
        })
    }
}

new Cart();
